import { GlobalStyles, useTheme } from '@mui/material';


export default function StyledNotistack() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '.SnackbarContent-root.SnackbarItem-contentRoot.SnackbarItem-variantSuccess': {
          color: theme.palette.getContrastText(theme.palette.background.paper),
          backgroundColor: theme.palette.background.paper
        }
      }}
    />
  );
}
