/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breakpoint } from '@mui/material';
import { Dot, MenuItem } from '../../component/SVGIcon';

const navConfig = [
  {
    title: 'General',
    children: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: MenuItem,
      },
    ],
  },
  {
    title: 'Menu',
    children: [
      {
        title: 'Accounts & Services',
        path: '/dashboard/account_service',
        icon: MenuItem,
      },
      {
        title: 'ส่งข้อความ',
        path: '/dashboard/message/compose',
        icon: MenuItem,
      },
      {
        title: 'ส่งข้อความจากไฟล์',
        path: '/dashboard/message/compose_csv',
        icon: MenuItem,
      },
    ],
  },
];

export default navConfig;

export const breakPoint = 'lg' as Breakpoint;
export const drawerWidth = 280;
export const contentWidth = {
  width: { [breakPoint]: `calc(100% - ${drawerWidth}px)` },
};
