import { Backdrop as MuiBackdrop, CircularProgress } from '@mui/material';

const Backdrop = () => {
  return (
    <MuiBackdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
}

export default Backdrop