import { initializeApp } from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCPMh_cMtLLJwBZvaZkJ4qGr2RQCsjJ1KA",
  authDomain: "dash-imst.firebaseapp.com",
  projectId: "dash-imst",
  storageBucket: "dash-imst.appspot.com",
  messagingSenderId: "299316356010",
  appId: "1:299316356010:web:0a1c3415b938da8eea01d3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
