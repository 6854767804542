import { styled, alpha, Stack } from '@mui/material'
import { CheckMarkCircle } from '../SVGIcon'

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: alpha(theme.palette.success.main, 0.16),
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const SuccessIcon = () => {
  return (
    <StyledStack justifyContent="center" alignItems="center" sx={{ color: 'success.main' }} >
      <CheckMarkCircle width="1em" />
    </StyledStack>
  )
}

export default SuccessIcon