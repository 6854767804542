import { IconButton } from '@mui/material';
import { Moon, Sun } from './SVGIcon';
import useColorMode from '../hook/useColorMode';

type Props = {};

const ToggleColorMode = (props: Props) => {
  const { toggleColorMode, mode } = useColorMode();

  const handleClick = () => {
    toggleColorMode();
  };
  return (
    <IconButton className="grow" onClick={handleClick}>
      {mode === 'light' ? <Moon width={20} /> : <Sun width={20} />}
    </IconButton>
  );
};

export default ToggleColorMode;
