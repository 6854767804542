import './App.css';
import ThemeProvider from './theme/ThemeProvider';
import Routes from './Route';
import { AuthProvider } from './context/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledChart } from './component/Chart';
import { SnackbarProvider } from 'notistack';
import { StyledNotiStack, SuccessIcon } from './component/Notistack';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <StyledChart />
        <StyledNotiStack />
        <SnackbarProvider
          iconVariant={{
            success: <SuccessIcon />,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
