import {
  alpha,
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuPopover from './MenuPopover';
import useAuth from '../hook/useAuth';
import { getAuth, signOut } from 'firebase/auth';
import app from '../config/firebase';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/dashboard/profile',
  },
];

function AccountPopover() {
  const { currentUser, profile } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const auth = getAuth(app)
      await signOut(auth)

    } catch (err) {
      console.error(err);
    }
  };

  const displayName = useMemo(() => {
    return profile?.name ? profile.name :
      currentUser?.displayName
        ? currentUser.displayName
        : currentUser?.email?.split('@')[0]!
  }, [currentUser?.displayName, currentUser?.email, profile?.name])

  const displayMail = useMemo(() => {
    return currentUser?.email ? currentUser.email : 'Other'
  }, [currentUser?.email])

  return (
    <>
      <Box className="grow">
        <IconButton
          onClick={handleClick}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          {/* <Avatar src={account.photoURL} alt="avatar-photo" /> */}
          <Avatar alt="avatar-text">{
            profile?.name ? profile.name[0].toUpperCase() :
              currentUser?.email && currentUser.email[0].toUpperCase()
          }</Avatar>
        </IconButton>
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap textTransform="capitalize">
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {displayMail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          SignOut
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export default AccountPopover;
