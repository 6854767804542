import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { handleAxoisError } from '../utils/axiosHelper';
import app from '../config/firebase';
import { getAuth, User } from 'firebase/auth';
// import { getProfile, Profile } from '../api/profile';

type Profile = {
  name: string;
};

type AuthContextType = {
  currentUser: User | null;
  setCurrentUser: Dispatch<SetStateAction<User | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  profile: Profile | undefined;
  setProfile: Dispatch<SetStateAction<Profile | undefined>>;
  token: string | null;
};
const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile>();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  // const { } = useGetProfileQuery()
  // const dispatch = useAppDispatch()

  useEffect(() => {
    getAuth(app).onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      try {
        if (user) {
          const token = await user.getIdToken();
          setToken(token);
        } else {
          setToken(null);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (!token) {
  //     return;
  //   }
  //   const hanldeGetProfile = () => {
  //     getProfile(token).then(profile => {
  //       setProfile(profile)
  //     }).catch(handleAxoisError)

  //   }
  //   const handleConnectSocket = () => {
  //     setSocket(io("http://localhost:8093", {
  //       auth: {
  //         token,
  //       }
  //     }))
  //   }
  //   // hanldeGetProfile();
  //   handleConnectSocket();

  // }, [token])

  // useEffect(() => {
  //   socket?.on('getConnectionStatus', (data) => {
  //     if (data.connected) {
  //       socket.emit('register', {})
  //     }
  //   });
  //   socket?.on('registerd', (data) => {
  //     console.log(data)
  //   })
  //   socket?.on('getNotification', (data) => {
  //     console.log(data)
  //   })
  // }, [socket]);

  const value = {
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    profile,
    setProfile,
    token,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
