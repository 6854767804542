/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  styled,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  Link,
  Button,
  Typography,
  Container,
  Box,
  FormControlLabel,
  FormGroup,
  AlertTitle,
  Alert,
} from '@mui/material';
import { useState } from 'react';
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Eye, EyeOff } from '../component/SVGIcon';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import useAuth from '../hook/useAuth';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

type LocationProps = {
  state: {
    from: Location;
  };
};

type Props = {};

const Login = (props: Props) => {
  // const { persist, setPersist, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const from = location.state?.from?.pathname || '/dashboard';
  // location.state?.from?.pathname
  const { currentUser } = useAuth();
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (!user || !pwd) {
        return;
      }
      const auth = getAuth();
      signInWithEmailAndPassword(auth, user, pwd)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setErrMessage(errorMessage);
        });
      // navigate(from, { replace: true });
    } catch (err) {
      console.error(err);
    }
  };

  // const handleChangeRemember = (
  //   evt: React.ChangeEvent<HTMLInputElement>,
  //   checked: boolean
  // ) => {
  //   setPersist(checked);
  // };

  if (currentUser) {
    return <Navigate to={from} replace />;
  }

  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h4" gutterBottom>
            Sign in to IMST
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Enter your details below.
          </Typography>

          {errMessage && (
            <Alert severity="error" sx={{ mb: 3 }}>
              Authentication: Error <strong>{errMessage}</strong>
            </Alert>
          )}

          <Stack spacing={3} sx={{ mb: 3 }}>
            <TextField
              name="username"
              label="Username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
            <TextField
              fullWidth
              name="password"
              label="Password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Box sx={{ width: '1em', height: '1em' }}>
                        {showPassword ? <Eye /> : <EyeOff />}
                      </Box>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            Login
          </Button>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
