/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box } from '@mui/material';
import DashboardAppbar from './DashboardAppbar';
import { Outlet } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { breakPoint } from './navConfig';

type Props = {
  appbarShadow?: boolean;
  children?: React.ReactNode;
};

const DashboardLayout = ({ appbarShadow = true, children }: Props) => {
  const [drawerState, setDrawerState] = React.useState(false);

  const toggleDrawer = () => setDrawerState((prev) => !prev);
  // const openDrawer = () => setDrawerState(true);
  const closeDrawer = () => setDrawerState(false);

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up(breakPoint)]: {
          display: 'flex',
          minHeight: '100%',
        },
      })}
    >
      <DashboardAppbar
        {...{ handleDrawerToggle: toggleDrawer, shadow: appbarShadow }}
      />
      {children || <Outlet />}
    </Box>
  );
};

export default DashboardLayout;
