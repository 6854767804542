import { AppBar, Box, IconButton, Stack, Toolbar, styled } from '@mui/material';
// import { MenuDash } from '../../component/SVGIcon';
import AccountPopover from '../../component/AccountPopover';
import { contentWidth, drawerWidth, breakPoint } from './navConfig';
import { alpha } from '@mui/material';
import ToggleColorMode from '../../component/ToggleColorMode';

const Shadow = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  width: 'calc(100% - 3rem)',
  boxShadow: theme.customShadows.z8,
}));

type Props = {
  handleDrawerToggle: () => void;
  shadow: boolean;
};

const DashboardAppbar = ({ handleDrawerToggle, shadow }: Props) => {
  return (
    <AppBar
      aria-label="header bar"
      position="fixed"
      color="transparent"
      sx={{
        // ...contentWidth,
        // ml: { [breakPoint]: `${drawerWidth}px` },
        boxShadow: 'none',
      }}
    >
      <Toolbar
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.background.default, 0.8),
          backdropFilter: 'blur(6px)',
          boxShadow: 'none',
        })}
      >
        {/* <IconButton
          className="grow"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { [breakPoint]: 'none' } }}
        >
          <MenuDash width={24} />
        </IconButton> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, [breakPoint]: 1.5 }}
        >
          <ToggleColorMode />
          <AccountPopover />
        </Stack>
      </Toolbar>
      {shadow && <Shadow />}
    </AppBar>
  );
};

export default DashboardAppbar;
