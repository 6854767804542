import React, { useMemo } from 'react';

import { CssBaseline, PaletteMode, useMediaQuery } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import componentsOverride from './overrides';
import palette, { paletteDark } from './palette';
import shape from './shape';
import typography from './typography';
import shadows, {
  customShadows,
  customShadowsDark,
  shadowsDark,
} from './shadows';

type Props = {
  children: React.ReactNode;
};

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
  mode: 'dark' || 'light',
});

function ThemeProvider({ children }: Props) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState<PaletteMode>(
    prefersDarkMode ? 'dark' : 'light'
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          console.log(prevMode);
          return prevMode === 'light' ? 'dark' : 'light';
        });
      },
      mode,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...(mode === 'light'
          ? palette
          : {
              ...palette,
              ...paletteDark,
            }),
      },
      shape,
      typography,
      shadows: {
        ...(mode === 'light' ? shadows : shadowsDark),
      },
      customShadows: {
        ...(mode === 'light' ? customShadows : customShadowsDark),
      },
      status: { danger: 'red' },
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

export default ThemeProvider;
