import { lazy, Suspense } from 'react';
import { Routes as Router, Route, Navigate } from 'react-router-dom';
import Backdrop from '../component/Backdrop';
import useAuth from '../hook/useAuth';

import RequireAuth from './RequireAuth';

import DashboardLayout from '../layout/dashboard';
import Page404 from '../page/Page404';
import Login from '../page/Login';
// import Home from '../page/Home';
const Home = lazy(() => import('../page/Home'));

const LoadingScreen = () => <Backdrop />;

function Routes() {
  const { loading } = useAuth();
  return loading ? (
    <LoadingScreen />
  ) : (
    <Router>
      <Route path="/" element={<Navigate to="dashboard" />} />

      <Route path="login" element={<Login />} />

      <Route element={<RequireAuth />}>
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route
            index
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Home />
              </Suspense>
            }
          />
          {/* <Route index element={<Home />} /> */}
          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>

      <Route path="*" element={<Page404 />} />
    </Router>
  );
}

export default Routes;
